<template>
  <el-row type="flex" justify="center" align="middle" class="login-row">
    <el-col :xs="20" :sm="16" :md="12" :lg="6">
      <el-tabs v-model="activeTab" type="border-card">
        <el-tab-pane name="Login" :label="$t('login.login')">
          <LoginForm @afterLoginSuccess="afterLoginSuccess" />
        </el-tab-pane>
        <el-tab-pane name="Register" :label="$t('login.register')">
          <RegisterForm />
        </el-tab-pane>
      </el-tabs>
    </el-col>
  </el-row>
</template>

<script>
import LoginForm from "@/views/LoginForm";
import RegisterForm from "@/views/RegisterForm";

export default {
  metaInfo() {
    return {
      title: "Login - Ivy-Way Academy"
    };
  },

  components: {
    LoginForm,
    RegisterForm
  },

  props: [],
  data: () => {
    return {
      activeTab: "login"
    };
  },
  async mounted() {
    this.activeTab = this.$route.name;
  },
  methods: {
    afterLoginSuccess() {
      let nextRoute = JSON.parse(localStorage.getItem("lastAccessRoutePath"));
      localStorage.setItem("lastAccessRoutePath", "");

      if (!nextRoute?.path || nextRoute.path === "/") {
        this.$router.push({ name: "Home" });
      } else {
        this.$router.push({
          path: nextRoute.path,
          query: nextRoute.query
        });
      }
    }
  }
};
</script>

<style scoped>
.login-row {
  position: relative;
  top: 100px;
}

.el-card /deep/ .el-card__header {
  background: #42a16a;
}

.clearfix {
  color: white;
}

li a {
  text-decoration: none;
}
</style>
